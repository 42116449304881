import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Webcam from 'react-webcam';
import '../styles/web-cam.css';
import { WEB_CAM_HEIGHT, WEB_CAM_WIDTH } from '../constants';

// Video options for the webcam
const videoConstraints = {
  width: WEB_CAM_WIDTH,
  height: WEB_CAM_HEIGHT,
  facingMode: 'user', // front facing webcam
};

//fingers by indices from tensorflow
const fingerIndices = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
};

// Web cam style
const webCamStyle = {
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
  zIndex: '1000',
  top: '74%',
};

// Canvas style
const canvasStyle = {
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
  zIndex: '2000',
  width: WEB_CAM_WIDTH,
  height: WEB_CAM_HEIGHT,
  top: '74%',
};

// Loading image style
const loadImageStyle = {
  zIndex: '2000',
  height: 85,
  width: 85,
  position: 'absolute',
  top: '80%',
  visibility: 'visible',
};

// 'Place your hands here' indication image style
const handImageStyle = {
  zIndex: '2000',
  height: 180,
  width: 275,
  position: 'absolute',
  top: '74%',
  visibility: 'hidden',
};

const WebCam = React.forwardRef((props, ref) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const handImageRef = useRef(null);
  const loadImageRef = useRef(null);

  const detectionBuffer = Array(15).fill(false); // Increased buffer size for more stability
  const bufferSize = detectionBuffer.length;
  const detectionThreshold = 0.2; // Lower threshold for more consistent detection
  const desiredFrameRate = 30; // Adjusted frame rate for better responsiveness

  useEffect(() => {
    let animationFrameId;
    let lastFrameTime = 0;
    const frameInterval = 1000 / desiredFrameRate;

    const render = (time) => {
      if (time - lastFrameTime >= frameInterval) {
        detect(props.tsModel);
        lastFrameTime = time;
      }
      animationFrameId = requestAnimationFrame(render);
    };

    render(0);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [props.tsModel]);

  useImperativeHandle(ref, () => ({
    getCanvasRef() {
      return getCanvasRef();
    },
    drawFingerJoint(x, y) {
      return drawFingerJoint(x, y);
    }
  }));

  function getCanvasRef() {
    return canvasRef.current;
  }

  // Method which draws the point circle at the location specified on the webcam canvas
  function drawFingerJoint(x, y, color) {
    const ctx = canvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.arc(WEB_CAM_WIDTH - x, y, 4, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
  }

  function drawResults(hands) {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    if (hands.length > 0 && hands[0].keypoints) {
      const fingers = Object.keys(fingerIndices);
      for (let k = 0; k < fingers.length; k++) {
        const points = fingerIndices[fingers[k]].map(idx => hands[0].keypoints[idx]);
        if (points.every(point => point)) { // Ensure all points are valid
          drawPath(points, ctx);

          points.forEach((point, idx) => {
            // Check if it's the last point in the finger (fingertip)
            if (idx === points.length - 1) {
              // If it's the thumb, color the tip yellow, else color it red
              const color = fingers[k] === 'thumb' ? 'yellow' : 'red';
              drawFingerJoint(point.x, point.y, color);  // Thumb tip in yellow, other tips in red
            } else {
              drawFingerJoint(point.x, point.y, 'yellow');  // Other joints in yellow
            }
          });
        }
      }
    }
  }

  function drawPath(points, ctx) {
    const region = new Path2D();
    region.moveTo(WEB_CAM_WIDTH - points[0].x, points[0].y);

    // Connect line
    for (let i = 1; i < points.length; i++) {
      region.lineTo(WEB_CAM_WIDTH - points[i].x, points[i].y);
    }
    ctx.strokeStyle = 'gold';
    ctx.stroke(region);
  }


  const detect = async (net) => {
    if (
      typeof webcamRef.current !== 'undefined' &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4 &&
      handImageRef.current &&
      loadImageRef.current
    ) {
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      const hand = await net.estimateHands(video);
      loadImageRef.current.style.visibility = 'hidden';
      const ctx = canvasRef.current.getContext('2d');
      
      ctx.globalCompositeOperation = 'source-over';

      // Update detection buffer
      detectionBuffer.shift();
      detectionBuffer.push(hand.length !== 0);

      // Calculate the average detection state
      const detectionAverage = detectionBuffer.reduce((sum, detected) => sum + (detected ? 1 : 0), 0) / bufferSize;

      if (detectionAverage >= detectionThreshold) {
        drawResults(hand);
        handImageRef.current.style.visibility = 'hidden';
      } else {
        handImageRef.current.style.visibility = 'visible';
      }

      props.triggerMultiTouchEventsWithOnlyOpenedFingers(hand);
    }
  };

  return (
    <div className="App">
      <Webcam
        ref={webcamRef}
        height={WEB_CAM_HEIGHT}
        width={WEB_CAM_WIDTH}
        videoConstraints={videoConstraints}
        mirrored={true}
        style={webCamStyle}
      />
      <img
        ref={loadImageRef}
        style={loadImageStyle}
        src={require('../loading.png')}
        alt="Loading..."
      ></img>
      <img
        ref={handImageRef}
        style={handImageStyle}
        src={require('../hand22.png')}
        alt="Place your hands here"
      ></img>
      <canvas
        id="webCamCanvas"
        ref={canvasRef}
        style={canvasStyle}
      />
    </div>
  );
});

export default WebCam;
