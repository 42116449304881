// filter.js

import React, { Component } from 'react';
import { SpectrogramContext } from './spectrogram-provider';
import { Segment, Button } from 'semantic-ui-react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { getMousePos, getFreq } from '../util/conversions';
import '../styles/filter.css';

const range = 10;
const updateTime = 200; // Time between each update while drawing on canvas
const minFreq = 20;
const maxFreq = 20000;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseDown: false,
      touchDown: false, // touchDown used for timed filter update
      cutoffFrequency: 1262, // Default value for cutoff frequency
      showFrequencySlider: false, // Determines if the frequency slider is shown
      currentFilterType: null, // 'highPass', 'lowPass', or 'vowel'
      showVowelButtons: false, // Determines if the vowel buttons are shown
    };

    // Bind methods that are not arrow functions
    this.handleCutoffFrequencyChange = this.handleCutoffFrequencyChange.bind(this);
    this.highPass = this.highPass.bind(this);
    this.lowPass = this.lowPass.bind(this);
    this.filterReset = this.filterReset.bind(this);
    this.vowelFilter = this.vowelFilter.bind(this);
    this.toggleVowelButtons = this.toggleVowelButtons.bind(this);
  }

  // Convert slider value to frequency
  frequencyFromSliderValue(value) {
    const exponent = value / 100; // Slider value is between 0 and 100
    const frequency = minFreq * Math.pow(maxFreq / minFreq, exponent);
    return frequency;
  }

  // Convert frequency to slider value
  sliderValueFromFrequency(frequency) {
    const sliderValue =
      100 * (Math.log(frequency / minFreq) / Math.log(maxFreq / minFreq));
    return sliderValue;
  }

  // Handle changes to the cutoff frequency slider
  handleCutoffFrequencyChange(value) {
    // Map the slider value (linear scale) to a logarithmic frequency
    const cutoffFrequency = this.frequencyFromSliderValue(value);

    this.setState({ cutoffFrequency }, () => {
      // Re-apply the filter with the new cutoffFrequency
      if (this.state.currentFilterType === 'highPass') {
        this.applyHighPassFilter();
      } else if (this.state.currentFilterType === 'lowPass') {
        this.applyLowPassFilter();
      }
    });
  }

  // Filter Presets/Resets
  filterReset() {
    this.heights = new Array(this.canvas.height);
    this.ctx.fillStyle = '#56caff';
    for (let i = 0; i < this.heights.length; i++) {
      this.heights[i] = this.canvas.width;
      this.ctx.fillRect(0, i, this.canvas.width, 1);
    }
    this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);
    this.renderAxesLabels();

    // Reset the cutoffFrequency state back to 1262 Hz and hide the frequency slider
    this.setState({
      cutoffFrequency: 1262,
      showFrequencySlider: false,
      currentFilterType: null,
      showVowelButtons: false, // Hide vowel buttons on reset
    });

    if (this.context.playSound) {
      this.context.playSound();
    }
  }

  componentDidMount() {
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');
      this.heights = new Array(this.canvas.height);

      // Load From Previous
      if (this.context.state.filterHeights) {
        for (let i = 0; i < this.heights.length; i++) {
          this.ctx.fillStyle = 'black';
          this.ctx.fillRect(0, i, this.canvas.width, 1); // Clears canvas for redraw of label
          this.heights[i] = this.context.state.filterHeights[i];
          this.ctx.fillStyle = '#56caff';
          this.ctx.fillRect(0, i, this.context.state.filterHeights[i], 1);
        }
      } else {
        this.ctx.fillStyle = '#56caff';
        for (let i = 0; i < this.heights.length; i++) {
          this.heights[i] = this.canvas.width;
          this.ctx.fillRect(0, i, this.canvas.width, 1);
        }
      }

      this.renderAxesLabels();
      window.setInterval(this.filtertimedupdate, updateTime);
    } else {
      console.error('Canvas element not found!');
    }
  }

  // High-pass filter implementation
  applyHighPassFilter = () => {
    const { cutoffFrequency } = this.state;
    const curve = 10;

    // Ensure cutoffFrequency is valid
    if (!cutoffFrequency || cutoffFrequency < minFreq || cutoffFrequency > maxFreq) {
      console.error('Invalid cutoff frequency:', cutoffFrequency);
      return;
    }

    this.heights = new Array(this.canvas.height);

    const cutoffPosition =
      (1 - Math.log(cutoffFrequency / minFreq) / Math.log(maxFreq / minFreq)) *
      this.canvas.height;

    for (let i = 0; i < this.heights.length; i++) {
      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, i, this.canvas.width, 1); // Clear canvas at this line

      // Calculate the exponent for the curve
      const exponent = (curve * (i - cutoffPosition)) / (this.canvas.height / 10);
      const rect = 1 / (1 + Math.exp(exponent));

      this.ctx.fillStyle = '#56caff';
      this.ctx.fillRect(0, i, rect * this.canvas.width, 1);
      this.heights[i] = rect * this.canvas.width;
    }

    this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);
    this.renderAxesLabels();

    setTimeout(() => {
      if (this.context.playSound) {
        this.context.playSound();
      }
    }, 100);
  };

  // Low-pass filter implementation
  applyLowPassFilter = () => {
    const { cutoffFrequency } = this.state;
    const curve = 10;

    // Ensure cutoffFrequency is valid
    if (!cutoffFrequency || cutoffFrequency < minFreq || cutoffFrequency > maxFreq) {
      console.error('Invalid cutoff frequency:', cutoffFrequency);
      return;
    }

    this.heights = new Array(this.canvas.height);

    const cutoffPosition =
      (1 - Math.log(cutoffFrequency / minFreq) / Math.log(maxFreq / minFreq)) *
      this.canvas.height;

    for (let i = 0; i < this.heights.length; i++) {
      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, i, this.canvas.width, 1); // Clear canvas at this line

      // Calculate the exponent for the curve
      const exponent = (curve * (cutoffPosition - i)) / (this.canvas.height / 10);
      const rect = 1 / (1 + Math.exp(exponent));

      this.ctx.fillStyle = '#56caff';
      this.ctx.fillRect(0, i, rect * this.canvas.width, 1);
      this.heights[i] = rect * this.canvas.width;
    }

    this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);
    this.renderAxesLabels();

    setTimeout(() => {
      if (this.context.playSound) {
        this.context.playSound();
      }
    }, 100);
  };

  // High-pass filter button handler
  highPass() {
    // Show the frequency slider and set the current filter type
    this.setState(
      { showFrequencySlider: true, currentFilterType: 'highPass', showVowelButtons: false },
      () => {
        // Apply the high-pass filter
        this.applyHighPassFilter();
      }
    );
  }

  // Low-pass filter button handler
  lowPass() {
    // Show the frequency slider and set the current filter type
    this.setState(
      { showFrequencySlider: true, currentFilterType: 'lowPass', showVowelButtons: false },
      () => {
        // Apply the low-pass filter
        this.applyLowPassFilter();
      }
    );
  }

  // Toggle vowel buttons visibility
  toggleVowelButtons() {
    this.setState({
      showVowelButtons: true,
      showFrequencySlider: false,
      currentFilterType: null,
    });
  }
/*
  // Apply a band-pass filter for vowel sounds
  applyBandPassFilter = (lowerFreq, upperFreq) => {
    const curve = 10;

    // Ensure frequencies are valid
    if (
      !lowerFreq ||
      !upperFreq ||
      lowerFreq < minFreq ||
      upperFreq > maxFreq ||
      lowerFreq >= upperFreq
    ) {
      console.error('Invalid frequency range:', lowerFreq, upperFreq);
      return;
    }

    this.heights = new Array(this.canvas.height);

    const lowerPosition =
      (1 - Math.log(lowerFreq / minFreq) / Math.log(maxFreq / minFreq)) *
      this.canvas.height;
    const upperPosition =
      (1 - Math.log(upperFreq / minFreq) / Math.log(maxFreq / minFreq)) *
      this.canvas.height;

    for (let i = 0; i < this.heights.length; i++) {
      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, i, this.canvas.width, 1); // Clear canvas at this line

      // Calculate the gain at this frequency
      const exponentLower = (curve * (i - lowerPosition)) / (this.canvas.height / 10);
      const exponentUpper = (curve * (upperPosition - i)) / (this.canvas.height / 10);
      const gainLower = 1 / (1 + Math.exp(exponentLower));
      const gainUpper = 1 / (1 + Math.exp(exponentUpper));
      const gain = gainLower * gainUpper;

      this.ctx.fillStyle = '#56caff';
      this.ctx.fillRect(0, i, gain * this.canvas.width, 1);
      this.heights[i] = gain * this.canvas.width;
    }

    this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);
    this.renderAxesLabels();

    setTimeout(() => {
      if (this.context.playSound) {
        this.context.playSound();
      }
    }, 100);
  };
*/
  // Updated applyBandPassFilter function using Gaussian-like function
  applyBandPassFilter = (lowerFreq, upperFreq) => {
    const curve = 10;
    const width = 100; // You can adjust this value to control the width of the Gaussian peak

    // Ensure frequencies are valid
    if (
      !lowerFreq ||
      !upperFreq ||
      lowerFreq < minFreq ||
      upperFreq > maxFreq ||
      lowerFreq >= upperFreq
    ) {
      console.error('Invalid frequency range:', lowerFreq, upperFreq);
      return;
    }

    this.heights = new Array(this.canvas.height);

    const lowerPosition =
      (1 - Math.log(lowerFreq / minFreq) / Math.log(maxFreq / minFreq)) *
      this.canvas.height;
    const upperPosition =
      (1 - Math.log(upperFreq / minFreq) / Math.log(maxFreq / minFreq)) *
      this.canvas.height;

    // Calculate the center frequency for the Gaussian curve (f1)
    const f1 = (lowerFreq);
    const f2 = (upperFreq);

    for (let i = 0; i < this.heights.length; i++) {
      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, i, this.canvas.width, 1); // Clear canvas at this line

      // Map the current position on the canvas to a frequency
      const currentFreq = minFreq * Math.pow(maxFreq / minFreq, (this.canvas.height - i) / this.canvas.height);

      // Calculate the gain using the Gaussian function
      const gain = Math.exp(-Math.pow(currentFreq - f1, 2) / Math.pow(f1/5, 2)) + Math.exp(-Math.pow(currentFreq - f2, 2) / Math.pow(f2/5, 2))

      this.ctx.fillStyle = '#56caff';
      this.ctx.fillRect(0, i, gain * this.canvas.width, 1);
      this.heights[i] = gain * this.canvas.width;
    }

    this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);
    this.renderAxesLabels();

    setTimeout(() => {
      if (this.context.playSound) {
        this.context.playSound();
      }
    }, 100);
  };


  // Handle vowel filter selection
  vowelFilter(vowel) {
    let lowerFreq, upperFreq;

    switch (vowel) {
      case 'a':
        lowerFreq = 700; // Approximate F1 for "A" (as in "father")
        upperFreq = 1100; // Approximate F2 for "A"
        break;
      case 'e':
        lowerFreq = 500; // Approximate F1 for "E" (as in "bed")
        upperFreq = 1700; // Approximate F2 for "E"
        break;
        /*
      case 'i':
        lowerFreq = 300; // Approximate F1 for "I" (as in "machine")
        upperFreq = 2200; // Approximate F2 for "I"
        break;
      */
      case 'i':
        lowerFreq = 350; // Updated lower frequency for "I"
        upperFreq = 2800; // Updated upper frequency for "I"
        break;
      case 'o':
        lowerFreq = 400; // Approximate F1 for "O" (as in "go")
        upperFreq = 800; // Approximate F2 for "O"
        break;
      case 'u':
        lowerFreq = 350; // Approximate F1 for "U" (as in "flute")
        upperFreq = 600; // Approximate F2 for "U"
        break;
      default:
        console.error('Unknown vowel:', vowel);
        return;
    }

    // Hide the frequency slider
    this.setState(
      {
        showFrequencySlider: false,
        currentFilterType: null,
      },
      () => {
        // Apply the band-pass filter for the selected vowel
        this.applyBandPassFilter(lowerFreq, upperFreq);
      }
    );
  }

  // Handling mouse events to play sound when interacting with the spectrogram
  onMouseDown = (e) => {
    let pos = getMousePos(this.canvas, e);
    pos.x = Math.floor(pos.x);
    pos.y = Math.floor(pos.y);

    this.ctx.fillStyle = 'black';
    this.ctx.fillRect(0, pos.y, this.canvas.width, range); // Clears canvas for redraw of label
    this.ctx.fillStyle = '#56caff';
    this.ctx.fillRect(0, pos.y, pos.x, range);
    for (let i = 0; i < range; i++) {
      this.heights[Math.round(pos.y + i)] = pos.x;
    }

    this.renderAxesLabels();
    this.setState({ mouseDown: true });

    // Play sound when interacting with spectrogram
    if (this.context.playSound) {
      this.context.playSound();
    }
  };

  onMouseMove = (e) => {
    if (this.state.mouseDown) {
      let pos = getMousePos(this.canvas, e);
      pos.x = Math.floor(pos.x);
      pos.y = Math.floor(pos.y);

      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, pos.y, this.canvas.width, range);
      this.ctx.fillStyle = '#56caff';
      this.ctx.fillRect(0, pos.y, pos.x, range);
      for (let i = 0; i < range; i++) {
        this.heights[Math.round(pos.y + i)] = pos.x;
      }

      this.renderAxesLabels();

      // Play sound when dragging on the canvas
      if (this.context.playSound) {
        this.context.playSound();
      }
    }
  };

  onMouseUp = (e) => {
    this.setState({ mouseDown: false });
    this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);

    // Ensure sound plays after interaction
    if (this.context.playSound) {
      this.context.playSound();
    }
  };

  onMouseOut = (e) => {
    if (this.state.mouseDown) {
      this.setState({ mouseDown: false });
      this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);

      // Ensure sound plays after interaction
      if (this.context.playSound) {
        this.context.playSound();
      }
    }
  };

  // Render the vertical frequency axis.
  renderAxesLabels() {
    const ticks = 5;
    const units = 'Hz';
    for (var i = 0; i <= ticks; i++) {
      var percent = i / ticks;
      var y = (1 - percent) * this.canvas.height;
      if (i === 0) {
        y -= 10;
      }
      if (i === ticks) {
        y += 10;
      }
      var x = this.canvas.width - 60;
      x = Math.floor(x);
      y = Math.floor(y);

      let freq = Math.max(1, getFreq(percent, minFreq, maxFreq));
      this.ctx.font = '12px Inconsolata';

      this.ctx.textAlign = 'right';
      this.ctx.fillStyle = 'white';
      this.ctx.fillText(freq, x, y);
      this.ctx.textAlign = 'left';
      this.ctx.fillStyle = 'white';
      this.ctx.fillText(units, x + 10, y);
      this.ctx.fillRect(x + 40, y, 30, 2);
    }
  }

  // Timed update of the filter while drawing on canvas
  filtertimedupdate = () => {
    if (this.state.mouseDown || this.state.touchDown) {
      this.context.setFilter(this.heights, this.canvas.width, this.canvas.height);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Segment className="menu-pane-container compact edit-filter-container">
          {/* Canvas container */}
          <div className="canvas-container">
            <canvas
              className="filter-canvas"
              onContextMenu={(e) => e.preventDefault()}
              onMouseDown={this.onMouseDown}
              onMouseUp={this.onMouseUp}
              onMouseMove={this.onMouseMove}
              onMouseOut={this.onMouseOut}
              ref={(c) => {
                this.canvas = c;
              }}
            />
          </div>

          {/* Conditionally render the frequency slider */}
          {this.state.showFrequencySlider && (
            <div className="input-group">
              <div style={{ marginBottom: '10px' }}>
                <label>
                  Cutoff Frequency (Hz): {Math.round(this.state.cutoffFrequency)}
                </label>
              </div>
              <Slider
                min={0}
                max={100}
                value={this.sliderValueFromFrequency(this.state.cutoffFrequency)}
                onChange={this.handleCutoffFrequencyChange}
                tooltip={false}
                step={0.1}
              />
            </div>
          )}

          {/* Content container with scroll */}
          <div className="filter-content">
            {/* Presets buttons */}
            <div className="vert no-padding">
              Presets:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button id="FilterPresets" onClick={this.filterReset}>
                Reset
              </Button>
              {!this.state.showVowelButtons && (
                <>
                  <Button id="FilterPresets" onClick={this.highPass}>
                    High-pass
                  </Button>
                  <Button id="FilterPresets" onClick={this.lowPass}>
                    Low-pass
                  </Button>
                </>
              )}
              {!this.state.showVowelButtons && (
                <Button id="FilterPresets" onClick={this.toggleVowelButtons}>
                  Vowel
                </Button>
              )}
            </div>

            {/* Vowel buttons */}
            {this.state.showVowelButtons && (
              <div className="vert no-padding" style={{ marginTop: '10px' }}>
                <Button id="FilterPresets" onClick={() => this.vowelFilter('a')}>
                  "Ah"
                </Button>
                <Button id="FilterPresets" onClick={() => this.vowelFilter('e')}>
                  "Eh"
                </Button>
                <Button id="FilterPresets" onClick={() => this.vowelFilter('i')}>
                  "Ee"
                </Button>
                <Button id="FilterPresets" onClick={() => this.vowelFilter('o')}>
                  "Oh"
                </Button>
                <Button id="FilterPresets" onClick={() => this.vowelFilter('u')}>
                  "Oo"
                </Button>
              </div>
            )}
          </div>
        </Segment>
      </React.Fragment>
    );
  }
}

Filter.contextType = SpectrogramContext;
export default Filter;
